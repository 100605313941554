import {
  siVimeo,
  siSkype,
  siQuora,
  siUpwork,
  siReddit,
  siTinder,
  siTumblr,
  siFlickr,
  siTiktok,
  siGithub,
  siTwitter,
  siBehance,
  siYoutube,
  siDiscord,
  siTelegram,
  siLinkedin,
  siFacebook,
  siSnapchat,
  siWhatsapp,
  siDribbble,
  siPinterest,
  siInstagram,
  siMessenger,
  siSoundcloud,
  siFoursquare,
} from "simple-icons";

export default {
  facebook: { color: "#1877F2", icon: siFacebook },
  twitter: { color: "#1DA1F2", icon: siTwitter },
  instagram: { color: "#E4405F", icon: siInstagram },
  snapchat: { color: "#FFFC00", icon: siSnapchat },
  youtube: { color: "#CD201F", icon: siYoutube },
  linkedin: { color: "#0A66C2", icon: siLinkedin },
  tiktok: { color: "#EE1D51", icon: siTiktok },
  messenger: { color: "#0099FF", icon: siMessenger },
  telegram: { color: "#0088CC", icon: siTelegram },
  whatsapp: { color: "#25D366", icon: siWhatsapp },
  github: { color: "#333333", icon: siGithub },
  dribbble: { color: "#EA4C89", icon: siDribbble },
  behance: { color: "#0057ff", icon: siBehance },
  upwork: { color: "#14a800", icon: siUpwork },
  skype: { color: "#00AFF0", icon: siSkype },
  pinterest: { color: "#BD081C", icon: siPinterest },
  reddit: { color: "#FF5700", icon: siReddit },
  foursquare: { color: "#33f", icon: siFoursquare },
  tumblr: { color: "#34465D", icon: siTumblr },
  soundcloud: { color: "#FF3300", icon: siSoundcloud },
  flickr: { color: "#FF0084", icon: siFlickr },
  quora: { color: "#B92B27", icon: siQuora },
  discord: { color: "#5865F2", icon: siDiscord },
  tinder: { color: "#FE3C72", icon: siTinder },
  vimeo: { color: "#1ab7ea", icon: siVimeo },
};
