<template>
  <div
    class="flex items-center p-2 border border-b-2 rounded-lg dark:bg-gray-850/50 dark:text-gray-300"
  >
    <div
      class="relative flex items-center justify-center flex-shrink-0 w-12 h-12 text-2xl rounded-md bg-primary-50 text-primary-300"
    >
      <img
        v-if="profile"
        class="rounded-md size-full"
        :src="profile.size['_200x200']"
        :alt="name"
        loading="lazy"
      />
      <Icon v-else name="IcRoundPerson" />
      <div
        v-if="isAvailable"
        class="absolute w-4 h-4 border-2 border-white rounded-full bg-success-500 -right-1 -bottom-1"
      />
    </div>
    <div class="flex flex-col ms-4">
      <p class="">{{ name }}</p>
      <p v-if="desc" class="text-xs text-gray-500">{{ desc }}</p>
    </div>
    <div class="flex items-center flex-shrink-0 ms-auto">
      <a
        v-if="callSupport"
        :href="`tel:+${mobile.numberWithCallingCode}`"
        target="_blank"
        class="p-2 text-gray-500 size-10 hover:text-primary-500 dark:hover:text-primary-400"
      >
        <Icon class="size-full" name="IcRoundCall" />
      </a>
      <a
        v-if="whatsappSupport"
        class="p-2 text-gray-500 size-10 hover:text-primary-500 dark:hover:text-primary-400"
        :href="`https://wa.me/${mobile.numberWithCallingCode}`"
        target="_blank"
      >
        <Icon class="size-full" name="MdiWhatsapp" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    desc: {
      type: String,
      default: null,
    },
    mobile: {
      type: Object,
      default: () => {},
    },
    profile: {
      type: Object,
      default: () => {},
    },
    whatsappSupport: Boolean,
    callSupport: Boolean,
    isAvailable: Boolean,
  },
};
</script>
